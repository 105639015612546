<template>
  <v-container class="pt-4" fluid>
    <v-row justify="end">
      <v-col cols="12" md="4" lg="3" xl="2">
        <VxDatePicker
          v-model="businessDate"
          label="Business date"
          :max="new Date()"
          :clearable="false"
          hide-details
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <BuyListClosed
        :buys="buys"
        :loading="loading"
        @click:buy="$emit('show', $event.id)"
      />
    </v-row>
  </v-container>
</template>

<script>
import { format, parseISO } from "date-fns";
import BuyListClosed from "./BuyListClosed.vue";
import { VxDatePicker } from "@/core-ui";
import { DAILY_BUYS } from "@/views/Report/AppView/graphql";

export default {
  name: "ClosedBuys",
  components: { BuyListClosed, VxDatePicker },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    businessDate: new Date(),
  }),
  apollo: {
    buys: {
      query: DAILY_BUYS,
      update: (data) => data.dailyBuys,
      variables() {
        return {
          storeId: this.storeId,
          businessDate: format(
            typeof this.businessDate === "string"
              ? parseISO(this.businessDate)
              : this.businessDate,
            "yyyy-MM-dd"
          ),
          status: "closed",
        };
      },
      skip() {
        return !this.storeId || !this.businessDate;
      },
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.buys.loading;
    },
  },
};
</script>
