<script>
import VxAppBar from "@/components/vx/VxAppBar";
import { injectActiveEmployee } from "@/mixins/employee";
import EmployeeAction from "@/components/employees/EmployeeAction.js";
import { mapGetters } from "vuex";
import BuyTab from "./components/BuyTab.vue";
import BuySearch from "./components/BuySearch";

export default {
  name: "BuysAppBar",
  components: {
    VxAppBar,
    EmployeeAction,
    BuyTab,
    BuySearch,
  },

  mixins: [injectActiveEmployee],

  props: {
    storeId: { type: String, default: "" },
    currentTab: {
      type: String,
      default: "open",
    },
  },

  computed: {
    ...mapGetters("buys", ["buysOpen", "buysComplete", "buysPending"]),
  },

  methods: {
    changeTab(tab) {
      // TODO - check if to use replace or push
      this.$router.replace({
        name: this.$route.name,
        query: {
          buysTab: tab,
        },
        preserveQuery: true,
      });
    },
    handleClick() {
      this.$router.push({ name: "buy.setup" });
    },
  },
};
</script>

<template>
  <VxAppBar :title="$t('buy.app.buyAppBar.title')" mobile-search>
    <template #search="{ back }">
      <BuySearch @back="back" />
    </template>
    <template #rightMenuItems>
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item ripple v-on="listeners" @click="handleAction(handleClick)">
          <v-list-item-title
            :class="{ 'text--disabled': isDisabled }"
            v-text="$t('buy.app.buyList.buySettings')"
          />
        </v-list-item>
      </EmployeeAction>
    </template>

    <template #extension>
      <v-tabs :value="currentTab" show-arrows grow>
        <BuyTab
          href="#pending"
          :count="buysPending.length"
          @click="changeTab('pending')"
        >
          Pending
        </BuyTab>

        <BuyTab
          href="#open"
          :count="buysOpen.length"
          @click="changeTab('open')"
        >
          Open
        </BuyTab>
        <BuyTab
          href="#complete"
          :count="buysComplete.length"
          @click="changeTab('complete')"
        >
          Complete
        </BuyTab>
        <BuyTab href="#closed" @click="changeTab('closed')">Closed</BuyTab>
      </v-tabs>
    </template>
  </VxAppBar>
</template>
