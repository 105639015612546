<script>
import { featureApi } from "@/core-ui";
import store from "@/store";
import { mapGetters } from "vuex";
import { hasChildDialog } from "@/mixins/routerParams";

import BuyListOpen from "./components/BuyListOpen.vue";
import BuyListComplete from "./components/BuyListComplete.vue";
import CheckinRequestsList from "./components/CheckinRequestsList.vue";
import ClosedBuys from "./components/ClosedBuys.vue";
import {
  BUY_VIEW,
  BUY_VIEW_BUY_UPDATED,
  handleBuyCreateUpdateVoid,
  CHECKIN_REQUESTS_QUERY,
  CHECKIN_REQUEST_UPDATED,
  handleCheckinRequestUpdated,
} from "./graphql";

export default {
  name: "BuysView",
  components: {
    BuyListOpen,
    BuyListComplete,
    CheckinRequestsList,
    ClosedBuys,
  },
  mixins: [hasChildDialog],
  props: {
    storeId: { type: String, default: "" },
    currentTab: { type: String, default: "" },
  },
  data: () => ({
    buys: [],
    buysLoading: 0,
    checkinRequests: [],
    checkinsLoading: 0,
  }),
  apollo: {
    checkinRequests: {
      query: CHECKIN_REQUESTS_QUERY,
      loadingKey: "checkinsLoading",
      subscribeToMore: [
        {
          document: CHECKIN_REQUEST_UPDATED,
          updateQuery(previousResult, context) {
            previousResult = previousResult ?? { checkinRequest: [] };
            const { checkinRequestUpdated } = context.subscriptionData.data;
            return handleCheckinRequestUpdated(
              previousResult,
              checkinRequestUpdated
            );
          },
        },
      ],
      variables() {
        return { storeId: this.storeId };
      },
      update({ checkinRequests }) {
        store.dispatch("buys/updateCheckinRequests", checkinRequests);
        return checkinRequests;
      },
    },
    buys: {
      // Query all buys where status in 'pending', 'open', 'complete', 'totag'
      query: BUY_VIEW,
      loadingKey: "buysLoading",
      subscribeToMore: [
        {
          // Subscribe to more buys, created and updated
          document: BUY_VIEW_BUY_UPDATED,
          updateQuery(previousResult, context) {
            previousResult = previousResult ?? { buys: [] };
            const { buyUpdated } = context.subscriptionData.data;
            return handleBuyCreateUpdateVoid(previousResult, buyUpdated);
          },
        },
      ],
      variables() {
        return { storeId: this.storeId };
      },
      update({ buys }) {
        store.dispatch("buys/updateBuys", buys);
        return buys;
      },
    },
  },
  computed: {
    ...mapGetters("buys", ["buysOpen", "buysComplete", "buysPending"]),
  },
  watch: {
    currentTab(value) {
      if (value !== "pending") {
        this.$apollo.queries.buys.refetch();
      } else if (value === "pending") {
        this.$apollo.queries.checkinRequests.refetch();
      }
    },
  },
  methods: {
    onShowBuy(buyId) {
      this.$router.push({
        name: "buys_edit",
        params: { buyId },
        query: { buyDetailTab: "buy" },
        preserveQuery: true,
      });
    },
    onShowCheckInRequest(checkinRequest) {
      if (featureApi.isVisible("checkinFlowVersion", "V2")) {
        this.$router.push({
          name: "buys_edit_checkinrequset",
          params: { checkinRequestId: checkinRequest?.id },
          preserveQuery: true,
        });
      } else {
        if (checkinRequest.customerId) {
          // If there is an assigned customer already, show the edit form
          this.$router.push({
            name: "buys_edit_checkinrequset",
            params: { checkinRequestId: checkinRequest.id },
            preserveQuery: true,
          });
        } else {
          // If there is no customer, show the create form, to connect to customer
          this.$router.push({
            name: "buys_new",
            query: {
              initialPhoneNumber: checkinRequest.phoneNumber,
              checkinRequestId: checkinRequest.id,
            },
            preserveQuery: true,
          });
        }
      }
    },
  },
};
</script>

<template>
  <v-tabs-items v-model="currentTab" class="buys-view fill-height" touchless>
    <v-tab-item value="pending">
      <CheckinRequestsList
        :items="checkinRequests"
        :store-id="storeId"
        :checkins-loading="checkinsLoading"
        @show="onShowCheckInRequest"
      />
    </v-tab-item>
    <v-tab-item value="open">
      <BuyListOpen
        :items="buysOpen"
        :store-id="storeId"
        :buys-loading="buysLoading"
        @show="onShowBuy"
      />
    </v-tab-item>
    <v-tab-item value="complete">
      <BuyListComplete
        :items="buysComplete"
        :store-id="storeId"
        :buys-loading="buysLoading"
        @show="onShowBuy"
      />
    </v-tab-item>
    <v-tab-item value="closed">
      <ClosedBuys :store-id="storeId" @show="onShowBuy" />
    </v-tab-item>
  </v-tabs-items>
</template>
