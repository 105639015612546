<template>
  <VxDataListItem @click="click">
    <template #desktop>
      <td class="text-no-wrap">
        <BuyNumber :trans-id="transId" />
      </td>
      <td>{{ customerFullName }}</td>
      <td>
        <div class="d-flex align-center">
          <EmployeeAvatarQuery
            v-if="buy.checkedInByEmployeeId"
            :employee-id="buy.checkedInByEmployeeId"
            class="mr-2"
            tooltip
          />
          <span>{{ checkedInAt }}</span>
        </div>
      </td>
      <td>
        <div class="d-flex align-center">
          <EmployeeAvatarQuery
            v-if="buy.completedByEmployeeId"
            :employee-id="buy.completedByEmployeeId"
            class="mr-2"
            tooltip
          />
          <span :class="completedAtClass">
            {{ completedAtText }}
          </span>
        </div>
      </td>
      <td>{{ timeSpent }}</td>
      <td>{{ totalTime }}</td>
      <td class="text-right text-data">{{ totalAmount }}</td>
    </template>
    <template #mobile>
      <v-list-item-action>
        <BuyNumber :trans-id="transId" />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ customerFullName }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ checkedInAt }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text class="text-data-1">
          {{ totalAmount }}
        </v-list-item-action-text>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<script>
import EmployeeAvatarQuery from "@/components/employees/EmployeeAvatarQuery";
import BuyNumber from "@/views/Buy/AppView/components/BuyNumber.vue";

import { isSameDay, differenceInMinutes, parseISO } from "date-fns";
import { VxDataListItem, formatNumber, formatDate } from "@/core-ui";

function parseTime(started, completed) {
  if (!started || !completed) {
    return "";
  }

  const diff = differenceInMinutes(parseISO(completed), parseISO(started));
  if (diff > 120) return "over 2 hours";
  else if (diff >= 60) return `1h ${diff % 60} min`;
  return `${diff} minutes`;
}

export default {
  name: "BuyLogListItem",
  components: {
    VxDataListItem,
    BuyNumber,
    EmployeeAvatarQuery,
  },
  props: {
    buy: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transId() {
      return this.buy.drsBuy?.transId;
    },
    customerFullName() {
      return this.buy.customer?.fullName;
    },
    completedAtClass() {
      return this.completedAt ? "" : "font-italic";
    },
    completedAtText() {
      return this.completedAt || this.estimatedPickupAt;
    },
    completedAt() {
      return this.formatDate(this.buy.completedAt);
    },
    estimatedPickupAt() {
      return this.formatDate(this.buy.estimatedPickupAt);
    },
    checkedInAt() {
      return this.formatDate(this.buy.checkedInAt);
    },
    timeSpent() {
      return parseTime(this.buy.startedAt, this.buy.completedAt);
    },
    totalTime() {
      return parseTime(this.buy.checkedInAt, this.buy.completedAt);
    },
    totalAmount() {
      const { totalAmount = 0 } = this.buy.drsBuy ?? {};

      if (totalAmount > 0) {
        return formatNumber(totalAmount, 2, "currency");
      } else if (this.buy.completedAt) {
        return "-";
      }

      return "";
    },
  },
  methods: {
    formatDate(date) {
      if (!this.buy.businessDate || !date) {
        return "";
      }

      return isSameDay(parseISO(this.buy.businessDate), parseISO(date))
        ? formatDate(date, "h:mm aaa")
        : formatDate(date, "h:mm aaa (MMM/do)");
    },
    click() {
      this.$emit("click", this.buy);
    },
  },
};
</script>
