<template>
  <span class="v-stopwatch">{{ displayTime }}</span>
</template>

<script>
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
} from "date-fns";
export default {
  interval: null,
  props: {
    start: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      time: new Date(),
    };
  },
  computed: {
    parsedStart() {
      return parseISO(this.start);
    },
    duration() {
      let hours = Math.floor(differenceInHours(this.time, this.parsedStart));
      let minutes = differenceInMinutes(this.time, this.parsedStart);
      let seconds = differenceInSeconds(this.time, this.parsedStart);
      if (hours > 0) {
        minutes = minutes % (60 * hours);
        seconds = seconds % (3600 * hours);
      }
      if (minutes > 0) {
        seconds = seconds % (60 * minutes);
      }
      return {
        hours,
        minutes,
        seconds,
      };
    },
    displayTime() {
      let { minutes, seconds } = this.duration;
      const hours = this.duration.hours > 0 ? `${this.duration.hours}:` : "";
      minutes = hours ? minutes.toString().padStart(2, "0") : minutes;
      seconds = seconds.toString().padStart(2, "0");
      return `${hours}${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.destroyTimer();
  },
  methods: {
    startTimer() {
      this.$options.interval = setInterval(this.updateTime, 1000);
    },
    destroyTimer() {
      clearInterval(this.$options.interval);
    },
    updateTime() {
      this.time = new Date();
    },
  },
};
</script>
