import { debounce } from "lodash";
import { BUYS_FILTERED } from "../../graphql";

function checkIfNoQueryDefined(query) {
  return !query || query?.trim() === "";
}

export default {
  name: "BuySearch",
  data() {
    return {
      select: null,
      query: null,
      filteredBuys: null,
      loadingBuys: false,
      focused: false,
    };
  },
  props: {
    storeId: { type: String, default: "" },
  },
  computed: {
    inputStyle() {
      return {
        borderRadius: "10px",
      };
    },
    hideNoData() {
      return this.noQueryDefined || this.loadingBuys || this.noSearchResults;
    },
    noSearchResults() {
      return this.filteredBuys === null;
    },
    noQueryDefined() {
      return checkIfNoQueryDefined(this.query);
    },
    noDataText() {
      return this.$t("buy.app.buyList.noData");
    },
  },
  watch: {
    query: "searchBuys",
  },
  methods: {
    setFocus() {
      this.focused = true;
    },

    clearFocus() {
      this.focused = false;
    },

    searchBuys: debounce(async function (query) {
      if (checkIfNoQueryDefined(query)) {
        this.filteredBuys = null;
        return;
      }

      try {
        this.loadingBuys = true;
        const { data } = await this.$apollo.query({
          query: BUYS_FILTERED,
          fetchPolicy: "network-only",
          variables: {
            storeId: this.storeId,
            filter: query,
          },
        });

        this.filteredBuys = data.buysFiltered;
      } catch {
        this.filteredBuys = null;
      } finally {
        this.loadingBuys = false;
      }
    }, 650), // -> magic number where deleting with backspace wont trigger query

    openDetailView(buyId) {
      if (!buyId) {
        return;
      }

      this.$router.push({
        name: "buys_edit",
        params: { buyId: buyId },
        query: {
          buyDetailTab: "buy",
        },
        preserveQuery: false,
      });

      this.$nextTick(() => {
        this.select = null;
      });
    },
  },
};
