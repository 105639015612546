<script>
import CheckInRequestItem from "./BuyListItem/CheckInRequestItem/CheckInRequestItem.vue";
import { VxDataList } from "@/core-ui";

import { isBefore, parseISO } from "date-fns";
export default {
  name: "CheckinRequestsList",
  components: {
    CheckInRequestItem,
    VxDataList,
  },
  props: {
    // Array of checkin requests
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    storeId: { type: String, default: "" },
    checkinsLoading: {
      type: [Boolean, Number],
      default: undefined,
    },
  },
  computed: {
    checkinRequestNewestFirst() {
      return this.items
        .slice()
        .sort(
          ({ createdAt: a }, { createdAt: b }) =>
            (isBefore(parseISO(a), parseISO(b)) && 1) ||
            (isBefore(parseISO(b), parseISO(a)) && -1) ||
            0
        );
    },
  },
};
</script>

<template>
  <VxDataList
    class="checkin-requests-list"
    :loading="checkinsLoading"
    :empty-data-text="'No checkin requests.'"
    :data-loading-text="'Loading checkin requests.'"
  >
    <template #body>
      <CheckInRequestItem
        v-for="item of checkinRequestNewestFirst"
        :key="`pending-${item.id}`"
        :store-id="storeId"
        :checkin-request="item"
        @show="(checkinRequest) => $emit('show', checkinRequest)"
      />
    </template>
  </VxDataList>
</template>

<style lang="scss">
.checkin-requests-list {
  .name-cell {
    width: 300px;
  }

  .signature-cell {
    /* THIS one has auto width */
  }

  .action-cell {
    width: 150px;
  }
}
</style>
