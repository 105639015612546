<script>
import { VxDataListItem } from "@/core-ui";
import BuyNumber from "../../BuyNumber";
import FlaggedName from "@/components/customers/FlaggedName";

export default {
  name: "BuyListItemComplete",
  components: {
    VxDataListItem,
    FlaggedName,
    BuyNumber,
  },
  props: {
    buy: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    quoteText() {
      return this.buy.quoteAmount
        ? this.$filters.dollars(this.buy.quoteAmount)
        : "-";
    },
  },
  methods: {
    onShowItem() {
      this.$emit("show", this.buy.id);
    },
  },
};
</script>

<template>
  <VxDataListItem class="rai-data py-2" @showItem="onShowItem">
    <template #desktop>
      <td class="pl-8">
        <BuyNumber :trans-id="buy.transId" class="text-truncate" />
      </td>
      <td>
        <FlaggedName
          :customer="buy.customer"
          class="buy-list-item-name font-weight-bold"
        />
        <span
          class="font-weight-bold pl-2 text--secondary"
          v-text="buy.containerNum"
        />
        -
        <span class="text--secondary" v-text="buy.containerDescription" />
        <span class="font-italic text--secondary" v-text="buy.containerSize" />
      </td>
      <td>
        <div
          class="amount-text align-center d-flex justify-end text--secondary"
        >
          {{ quoteText }}
        </div>
      </td>
    </template>

    <template #mobile>
      <v-list-item-content
        class="item__content buy-number-cell-mobile py-0 align-self-start"
      >
        <BuyNumber :trans-id="buy.transId" hide-prefix class="text-truncate" />
      </v-list-item-content>
      <v-list-item-content class="item__content py-0 pl-2 align-self-start">
        <FlaggedName :customer="buy.customer" class="d-inline" />
        <v-list-item-subtitle>
          <span class="font-weight-bold" v-text="buy.containerNum" /> -
          <span v-text="buy.containerDescription" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="my-0 align-self-baseline">
        <v-list-item-action-text
          class="item__content amount-text-mobile text-right"
        >
          {{ quoteText }}
        </v-list-item-action-text>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<style scoped>
.buy-number-cell-mobile {
  max-width: 40px;
}

.buy-list-item-name {
  display: inline !important;
}

.amount-text {
  height: 36px;
}

.amount-text-mobile {
  min-width: 50px;
}
</style>
