<script>
import BuyListItemComplete from "./BuyListItem/BuyListItemComplete";
import { VxDataList } from "@/core-ui";
import { VxDataListGroup } from "@/core-ui";
import { map, groupBy } from "lodash";

import { format, isYesterday, isTomorrow, isToday, parseISO } from "date-fns";

// Sorts buys by putting the latest complete buy first
function sortBuysByCompletedAt(array) {
  return array.sort(function (x, y) {
    return y.completedAt.getTime() - x.completedAt.getTime();
  });
}

export default {
  name: "BuyListComplete",
  components: {
    BuyListItemComplete,
    VxDataList,
    VxDataListGroup,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    buysLoading: {
      type: [Boolean, Number],
      default: undefined,
    },
  },
  data: () => ({
    columnCssClasses: ["buy-number-cell", "name-container-cell", "amount-cell"],
  }),
  computed: {
    groupedItems() {
      const grouped = groupBy(
        sortBuysByCompletedAt(this.computedItems),
        (item) => item.completedAtDate
      );
      const groupedMapped = map(grouped, (values, key) => {
        return { name: key, items: values };
      });
      return groupedMapped;
    },
    computedItems() {
      return this.items.map((item) => {
        const timestamp = item.completedAt;
        return {
          ...item,
          completedAt: new Date(item.completedAt),
          completedAtDate: format(parseISO(timestamp), "yyyy-MM-dd"),
        };
      });
    },
  },
  methods: {
    // Turns a yyyy-mm-dd into a friendly date
    formatDateSubheader(date) {
      const dateParsed = parseISO(date);
      const strFormat =
        (isToday(dateParsed) && "'Today'") ||
        (isYesterday(dateParsed) && "'Yesterday'") ||
        (isTomorrow(dateParsed) && "'Tomorrow'") ||
        "MMM d";
      return format(dateParsed, strFormat);
    },
    regroupBuys(partitions) {
      return partitions.reduce((acc, b) => {
        acc[b.name] = b.items;
        return acc;
      }, {});
    },
    // Sorts the buys
    sortBuys(groupedBuys) {
      const regroupBuys = this.regroupBuys(groupedBuys);
      return Object.keys(regroupBuys)
        .sort()
        .reverse()
        .reduce((accum, time) => {
          accum[time] = regroupBuys[time];
          return accum;
        }, {});
    },
    show(buyId) {
      this.$emit("show", buyId);
    },
  },
};
</script>

<template>
  <VxDataList
    class="buys-completed-list py-0"
    :loading="buysLoading"
    :empty-data-text="'No completed buys.'"
    :data-loading-text="'Loading completed buys.'"
    dense
    is-list
  >
    <template #groups>
      <VxDataListGroup
        v-for="date of Object.keys(sortBuys(groupedItems))"
        :key="date"
        :column-css-classes="columnCssClasses"
        :group-title="formatDateSubheader(date)"
      >
        <BuyListItemComplete
          v-for="item of regroupBuys(groupedItems)[date]"
          :key="`complete-${date}-${item.id}`"
          :buy="item"
          @show="(buyId) => $emit('show', buyId)"
        />
      </VxDataListGroup>
    </template>
  </VxDataList>
</template>

<style lang="scss">
.buys-completed-list {
  .buy-number-cell {
    width: 110px;
  }

  .name-container-cell {
    /* THIS one has auto width */
  }

  .amount-cell {
    width: 120px;
  }

  .group-title-mobile {
    height: fit-content;
    background-color: var(--v-ui-lighten5);
  }
}
</style>
