<template>
  <v-btn
    v-if="!hasSorter"
    :color="color"
    data-test-ref="start-sorting"
    class="px-0"
    :loading="loading"
    :text="text"
    @click.stop="start"
    v-text="label"
  />
  <Stopwatch
    v-else-if="buy.status === 'open'"
    data-test-ref="stopwatch"
    :start="buy.startedAt"
  />
</template>

<script>
import SorterSelectorBase from "./Base";
import Stopwatch from "@/components/util/Stopwatch";

export default {
  name: "SorterSelectorMobile",
  components: {
    Stopwatch,
  },
  extends: SorterSelectorBase,
};
</script>
