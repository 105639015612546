<script>
//import FlaggedName from "@/components/customers/FlaggedName";
import { VxDataListItem } from "@/core-ui";
import { iget } from "@/utils/lib";
import gql from "graphql-tag";
import { formatPhoneNumber } from "@/utils/phone";
import { mapMutations } from "vuex";

export default {
  name: "CheckInRequestItem",
  components: {
    VxDataListItem,
    //FlaggedName,
  },
  props: {
    checkinRequest: {
      type: Object,
      default: () => ({}),
    },
    storeId: { type: String, default: "" },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    customerName() {
      return iget(this.checkinRequest, "fullName");
    },
    phoneNumber() {
      return formatPhoneNumber(iget(this.checkinRequest, "phoneNumber"));
    },
    customerHasSigned() {
      return !!iget(this.checkinRequest, "signatureUrl");
    },
    customerHasIdOnFile() {
      return !!(
        iget(this.checkinRequest, "licenseVerifiedAt") ||
        // TODo - what to do with this ???? as we do not have any customer at this moment ?
        iget(this.checkinRequest, "hasDriverLicense")
      );
    },
  },
  methods: {
    iget,
    ...mapMutations("snackbar", ["showSnackbar"]),
    async deleteCheckinRequest() {
      const vm = this;
      this.loading = true;
      const self = this;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation VOID_CHECKIN_REQUEST($id: ID!) {
              checkinRequestVoid(id: $id) {
                errors
              }
            }
          `,
          variables: { id: this.checkinRequest.id },
          update(
            store,
            {
              data: {
                checkinRequestVoid: { errors },
              },
            }
          ) {
            if (errors && errors.length > 0) {
              self.showSnackbar({
                text: `Error occurred while deleting a checkin request ${errors}`,
              });
              return;
            }
          },
        });
      } catch (error) {
        // Maybe handle this better?
        self.showSnackbar({
          text: `Error occurred while deleting checkin request: ${error}`,
        });
        return;
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <VxDataListItem @click.stop="$emit('show', checkinRequest)">
    <template #desktop>
      <td class="rai-data name-cell">
        <template v-if="customerName">
          {{ customerName }}
          <!-- TODO - what to do with flagged customer -->
          <!-- <FlaggedName :customer="customer" /> -->
        </template>
        <template v-else>
          <span v-text="phoneNumber" />
        </template>
      </td>
      <td class="rai-data signature-cell">
        <div class="ellipsis-outer">
          <div class="ellipsis-inner">
            <v-icon
              v-if="customerHasIdOnFile"
              class="mr-2"
              v-text="`$vuetify.icons.license`"
            />
            <v-tooltip v-if="customerHasSigned">
              <template #activator="{ on }">
                <v-icon
                  class="mr-2"
                  v-on="on"
                  v-text="`$vuetify.icons.signature`"
                />
              </template>
              <span
                style="
                  position: absolute;
                  left: 3ch;
                  color: rgba(0, 0, 0, 0.88);
                "
                >Customer signature</span
              >
              <img
                :src="checkinRequest.signatureUrl"
                :alt="`${customerName}'s signature`"
              />
            </v-tooltip>
          </div>
        </div>
      </td>
      <td class="rai-data action-cell">
        <v-btn
          text
          color="primary"
          @click.stop="deleteCheckinRequest"
          v-text="`Delete`"
        />
      </td>
    </template>

    <template #mobile>
      <v-list-item-content class="item__content pt-0 pb-3">
        <template v-if="customerName">
          {{ customerName }}
          <!-- TODO - what to do with flagged customer -->
          <!-- <FlaggedName :customer="customer" /> -->
        </template>
        <template v-else>
          <span v-text="phoneNumber" />
        </template>
      </v-list-item-content>
      <v-list-item-content class="item__content pt-0 pb-3">
        <v-icon
          v-if="customerHasIdOnFile"
          class="mr-2"
          v-text="`$vuetify.icons.license`"
        />
        <v-tooltip v-if="customerHasSigned">
          <template #activator="{ on }">
            <v-icon
              class="mr-2"
              v-on="on"
              v-text="`$vuetify.icons.signature`"
            />
          </template>
          <span
            style="position: absolute; left: 3ch; color: rgba(0, 0, 0, 0.88)"
            >Customer signature</span
          >
          <img
            :src="checkinRequest.signatureUrl"
            :alt="`${customerName}'s signature`"
          />
        </v-tooltip>
      </v-list-item-content>
      <v-list-item-action-text class="primary--text item__content">
        <v-btn
          text
          color="primary"
          @click.stop="deleteCheckinRequest"
          v-text="`Delete`"
        />
      </v-list-item-action-text>
    </template>
  </VxDataListItem>
</template>

<style scoped></style>
