<script>
import BuyLogList from "@/views/Report/AppView/components/BuyLogList.vue";

export default {
  name: "BuyListClosed",
  extends: BuyLogList,
  computed: {
    loadingText() {
      return this.$t("buy.app.buyListClosed.loadingText");
    },
    emptyText() {
      return this.$t("buy.app.buyListClosed.emptyText");
    },
  },
};
</script>
