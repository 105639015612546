<script>
import get from "lodash/get";
import gql from "graphql-tag";

const EMPLOYEE_AVATAR = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      id
      fullName
      avatarUrl
      drsEmployeeCode
    }
  }
`;

/** * @deprecated use `EmployeeAvatar` component */
export default {
  name: "EmployeeAvatar",
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: "32px",
    },
    employeeId: {
      type: String,
      default: "",
    },
    list: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    defaultInitials: {
      type: String,
      default: "",
    },
    defaultFullName: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    employee: {},
  }),
  apollo: {
    employee: {
      query: EMPLOYEE_AVATAR,
      variables() {
        return { id: this.employeeId };
      },
      fetchPolicy: "cache-only",
      skip() {
        return !this.employeeId;
      },
    },
  },
  computed: {
    src() {
      return this.url || get(this.employee, "avatarUrl");
    },
    hasAvatarUrl() {
      return !!this.src;
    },
    drsInitials() {
      return get(this.employee, "drsEmployeeCode");
    },
    initials() {
      if (this.drsInitials) return this.drsInitials;

      if (!this.fullName && this.defaultInitials) {
        return this.defaultInitials;
      }

      if (!this.fullName) {
        return "N/A";
      }

      return (
        this.fullName
          .split(" ")
          .map((e) => e.charAt(0))
          .filter((e) => /[A-Z0-9]/.test(e))
          .slice(0, 2)
          .join("")
          .toUpperCase() || ""
      );
    },
    alt() {
      return `${get(this.employee, "fullName", this.defaultFullName)}'s avatar`;
    },
    on() {
      return this.$listeners;
    },
    fontSize() {
      if (!this.size) return "30px";
      try {
        const sizeInt = parseInt(this.size, 10);
        let sizeDiv = sizeInt / 36;
        if (sizeDiv === 0) {
          return "";
        }
        if (sizeDiv >= 1) {
          sizeDiv = Math.floor(sizeDiv);
        }
        return `${sizeDiv}em`;
      } catch (error) {
        return "30px";
      }
    },
    color() {
      return (
        (!this.employeeId && "ui") ||
        (!this.hasAvatarUrl && !this.drsInitials && "secondary lighten-2") ||
        (!this.hasAvatarUrl && "primary lighten-1") ||
        ""
      );
    },
    class() {
      return [!this.hasAvatarUrl && `white--text`].filter((e) => e);
    },
    fullName() {
      return get(this, "employee.fullName", this.defaultFullName || "");
    },
  },
};
</script>

<template>
  <span v-if="employeeId" class="rai-avatar rai-avatar--employee">
    <v-progress-circular
      v-if="$apollo.queries.employee.loading"
      color="primary"
      indeterminate
    />
    <v-tooltip v-else :disabled="!fullName.length || !tooltip" top>
      <template #activator="{ on: tooltipOn }">
        <v-avatar
          v-bind="$attrs"
          :color="color"
          :class="[!hasAvatarUrl && `white--text`].filter((e) => e)"
          :size="size"
          v-on="{ ...on, ...tooltipOn }"
        >
          <img v-if="hasAvatarUrl" :alt="alt" :src="src" />
          <span v-else :style="{ fontSize }" v-text="initials" />
        </v-avatar>
      </template>
      <span v-text="fullName" />
    </v-tooltip>
  </span>
  <span v-else class="rai-avatar">
    <v-avatar
      v-bind="$attrs"
      color="ui lighten-1"
      :class="[!hasAvatarUrl && `white--text`].filter((e) => e)"
      :size="size"
      v-on="on"
    >
      <v-icon v-if="icon" v-text="icon" />
      <img v-else-if="url" :src="url" />
      <span v-else :style="{ fontSize }" v-text="initials || `?`" />
    </v-avatar>
  </span>
</template>

<style>
.rai-avatar {
  border-radius: 100%;
}
</style>
