<template>
  <VxDataList
    v-bind="$attrs"
    :headers="headers"
    :sort-options="sortOptions"
    :data-loading-text="loadingText"
    :empty-data-text="emptyText"
    @update:sort="onSortUpdate"
  >
    <template #body>
      <BuyLogListItem
        v-for="buy in sortedBuys"
        :key="buy.id"
        :buy="buy"
        @click="$emit('click:buy', $event)"
      />
    </template>
  </VxDataList>
</template>

<script>
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import BuyLogListItem from "./BuyLogListItem.vue";

import { differenceInMinutes, parseISO } from "date-fns";
import {
  VxDataList,
  PaginationOptionsUtils,
  LocalPaginationUtils,
  SORT_ORDER,
} from "@/core-ui";

function sortByProcessingTime(item, sortOrder) {
  if (!item.startedAt || !item.completedAt) {
    return sortEmptyValueLast(sortOrder);
  }

  return differenceInMinutes(
    parseISO(item.completedAt),
    parseISO(item.startedAt)
  );
}

function sortByTotalWait(item, sortOrder) {
  if (!item.checkedInAt || !item.completedAt) {
    return sortEmptyValueLast(sortOrder);
  }

  return differenceInMinutes(
    parseISO(item.completedAt),
    parseISO(item.checkedInAt)
  );
}

function sortByCompleted(item) {
  return item.completedAt ? item.completedAt : item.estimatedPickupAt;
}

function sortEmptyValueLast(sortOrder) {
  return sortOrder === SORT_ORDER.ASC
    ? Number.MAX_SAFE_INTEGER
    : Number.MIN_SAFE_INTEGER;
}

export default {
  name: "BuyLogList",
  components: { VxDataList, BuyLogListItem },
  props: {
    buys: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    sortOptions: PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "drsBuy.transId",
    }),
  }),
  computed: {
    sortedBuys() {
      const { sortBy, sortOrder } = this.sortOptions;
      return orderBy(
        this.buys,
        [
          (item) => {
            if (sortBy === "processingTime") {
              return sortByProcessingTime(item, sortOrder);
            }

            if (sortBy === "totalWait") {
              return sortByTotalWait(item, sortOrder);
            }

            if (sortBy === "completed") {
              return sortByCompleted(item);
            }

            const sortByValue = get(item, sortBy);

            if (sortBy === "drsBuy.totalAmount" && !sortByValue) {
              return sortEmptyValueLast(sortOrder);
            }

            if (sortBy === "checkedInAt" && !sortByValue) {
              return sortEmptyValueLast(sortOrder);
            }

            return sortByValue;
          },
        ],
        [sortOrder === SORT_ORDER.ASC ? "asc" : "desc"]
      );
    },
    headers() {
      return [
        {
          propertyPath: "drsBuy.transId",
          label: this.$t("report.app.buyLogList.headers.buy"),
          sortable: true,
          cssClass: "transId-cell",
        },
        {
          propertyPath: "customer.fullName",
          label: this.$t("report.app.buyLogList.headers.customer"),
          sortable: true,
          cssClass: "name-cell",
        },
        {
          propertyPath: "checkedInAt",
          label: this.$t("report.app.buyLogList.headers.checkedInAt"),
          sortable: true,
          cssClass: "checkedIn-cell",
        },
        {
          propertyPath: "completed",
          label: this.$t("report.app.buyLogList.headers.completedAt"),
          sortable: true,
          cssClass: "completed-cell",
        },
        {
          propertyPath: "processingTime",
          label: this.$t("report.app.buyLogList.headers.processingTime"),
          sortable: true,
          cssClass: "started-cell",
        },
        {
          propertyPath: "totalWait",
          label: this.$t("report.app.buyLogList.headers.totalWait"),
          sortable: true,
          cssClass: "started-cell",
        },
        {
          propertyPath: "drsBuy.totalAmount",
          label: this.$t("report.app.buyLogList.headers.totalDollars"),
          sortable: true,
          cssClass: "started-cell",
        },
      ];
    },
    loadingText() {
      return this.$t("report.app.buyLogList.loadingText");
    },
    emptyText() {
      return this.$t("report.app.buyLogList.emptyText");
    },
  },
  methods: {
    onSortUpdate(header) {
      LocalPaginationUtils.updateSort(
        this.sortOptions,
        { page: 1, itemsPerPage: this.buys.length },
        header.propertyPath
      );
    },
  },
};
</script>
