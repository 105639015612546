<template>
  <div class="sorter-selector pa-0 text-right">
    <!-- start sorting button -->
    <template v-if="!hasSorter">
      <v-btn
        data-test-ref="start-sorting"
        class="px-0"
        :loading="loading"
        :text="text"
        :color="color"
        @click.stop="start"
        v-text="label"
      />
    </template>
    <template v-else>
      <div class="d-flex align-center justify-end">
        <!-- employee avatar and timer -->
        <template v-if="!hideAvatar">
          <EmployeeSelect
            v-if="isEditable"
            data-test-ref="employee-select"
            :loading="loading"
            :value="employee"
            :employees="clockedInEmployees"
            size="32"
            @input="changeSorter"
          />
          <EmployeeAvatar
            v-else
            data-test-ref="employee-avatar"
            :employee-id="employee.id"
            size="24"
          />
        </template>
        <Stopwatch
          v-if="buy.status === 'open'"
          data-test-ref="stopwatch"
          class="ml-2 rai-data"
          :start="buy.startedAt"
        />
        <span
          v-if="buy.status !== 'open'"
          data-test-ref="elapsed"
          class="ml-2 rai-data"
          v-text="get(buy, 'sortTime')"
        />
      </div>
    </template>
  </div>
</template>

<script>
// * `buy` prop, so mutation can happen directly in this component
// * apollo for the employee
// ---------
// > on load
// - if value present,
//    |- if buy is open, and disabled is false, show employee selector with the selected employee and a stopwatch
//    |- if buy.status >= open, show employee
// - else, show a "start sorting button"
// ---------
// > on "start sorting button" is pressed
//    - if teammode, use the active employee
//      |
//      |- emit(input, activeEmployee)
//    - else, show an employee select. only clocked in employees should show
//      |
//      |- emit(input, selectedEmployee)
import SorterSelectorBase from "./Base";
import EmployeeAvatar from "@/components/employees/Avatar";
import EmployeeSelect from "@/components/util/RaiEmployeeSelect";
import Stopwatch from "@/components/util/Stopwatch";

import { format } from "date-fns";
import { get } from "lodash";

export default {
  name: "SorterSelectorDesktop",
  components: {
    EmployeeAvatar,
    EmployeeSelect,
    Stopwatch,
  },
  extends: SorterSelectorBase,
  methods: {
    format,
    get,
  },
};
</script>
