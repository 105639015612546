<script>
import ListItemTitle from "../ListItemTitle";
import BuyListItemBase from "../BuyListItemBase";

export default {
  name: "BuyListItemFiltered",
  components: {
    ListItemTitle,
  },
  extends: BuyListItemBase,
  computed: {
    quoteText() {
      return this.$filters.dollars(this.buy.quoteAmount);
    },
  },
  methods: {
    show() {
      this.$emit("show", this.buy.id);
    },
  },
};
</script>

<template>
  <v-list-item v-bind="$attrs" two-line v-on="$listeners">
    <v-list-item-content class="pt-0 pb-3">
      <ListItemTitle :trans-id="buy.transId" :customer="buy.customer" />
      <v-list-item-subtitle>
        <span class="font-weight-bold" v-text="buy.containerNum" /> -
        <span v-text="buy.containerDescription" />
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text class="primary--text">
      {{ quoteText }}
    </v-list-item-action-text>
  </v-list-item>
</template>
