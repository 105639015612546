<template>
  <span class="buy-number rai-data">
    <span v-if="!hidePrefix" class="font-weight-light">{{ prefix }}</span>
    <span class="font-weight-bold">{{ number }}</span>
  </span>
</template>

<script>
export default {
  name: "BuyNumber",
  props: {
    transId: {
      type: [Number, String],
      default: "",
    },
    hidePrefix: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prefix() {
      const value = this.transId?.toString() ?? "";
      return value.substr(0, value.length - 3);
    },
    number() {
      return this.transId?.toString().slice(-3) ?? "";
    },
  },
};
</script>

<style lang="scss">
.buy-number {
  display: inline-flex;
  align-items: center;
}
</style>
