<template>
  <v-tab
    v-bind="$attrs"
    :ripple="$vuetify.breakpoint.mdAndUp"
    v-on="$listeners"
  >
    <v-badge color="primary" :content="count" :value="count" inline>
      <slot />
    </v-badge>
  </v-tab>
</template>

<script>
export default {
  name: "BuyTab",
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>
