<template>
  <v-list-item-title>
    <BuyNumber :trans-id="transId" hide-prefix class="d-inline mr-1" />
    <FlaggedName :customer="customer" class="d-inline" />
    <slot />
  </v-list-item-title>
</template>

<script>
import BuyNumber from "../BuyNumber.vue";
import FlaggedName from "@/components/customers/FlaggedName.vue";

export default {
  name: "ListItemTitle",
  components: {
    BuyNumber,
    FlaggedName,
  },
  props: {
    transId: {
      type: [String, Number],
      default: "",
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
