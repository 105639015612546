import desktop from "./Desktop";
import mobile from "./Mobile";

import { responsiveComponent } from "@/utils/lib";

export default {
  functional: true,

  render(h, ctx) {
    const component = responsiveComponent({
      breakpoint: ctx.parent.$vuetify.breakpoint,
      mobile,
      desktop,
    });

    return h(component, {
      props: ctx.props,
      ...ctx.data,
    });
  },
};
