<script>
export default {
  name: "NewDetailButton",
  props: {
    storeId: { type: String, default: "" },
    routeName: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <v-btn
    fab
    fixed
    bottom
    right
    color="accent"
    class="backstock-fab"
    @click="$router.push({ name: routeName, preserveQuery: true })"
  >
    <v-icon large>$add</v-icon>
  </v-btn>
</template>
