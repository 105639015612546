<script>
import BuySearchBase from "./Base";
import BuyListItemFiltered from "../BuyListItem/BuyListItemFiltered";
import { VxAutocomplete } from "@/core-ui";

export default {
  name: "BuySearchMobile",
  components: {
    BuyListItemFiltered,
    VxAutocomplete,
  },
  extends: BuySearchBase,
};
</script>

<template>
  <VxAutocomplete
    v-model="select"
    :loading="loadingBuys"
    :items="filteredBuys"
    :item-value="'id'"
    :item-text="'transId'"
    :search-input.sync="query"
    append-icon=""
    autofocus
    auto-select-first
    clearable
    no-filter
    :no-data-text="noDataText"
    :flat="!focused"
    solo
    :style="inputStyle"
    :hide-no-data="hideNoData"
    hide-details
    :placeholder="$t('buy.app.buyList.searchTitleMobile')"
    @change="openDetailView"
    @focus="setFocus"
    @blur="clearFocus"
  >
    <template #prepend-inner>
      <v-icon class="mr-3" @click="$emit('back')">$back</v-icon>
    </template>
    <template slot="item" slot-scope="{ item }">
      <BuyListItemFiltered :buy="item" @show="openDetailView" />
    </template>
  </VxAutocomplete>
</template>
