<script>
import { VxDataListItem } from "@/core-ui";
import ListSorterSelector from "../../ListSorterSelector";
import BuyNumber from "../../BuyNumber.vue";
import Avatar from "@/components/employees/Avatar";
import FlaggedName from "@/components/customers/FlaggedName";
import { isAfter, addMinutes, parseISO } from "date-fns";

export default {
  name: "BuyListItemOpen",
  components: {
    VxDataListItem,
    ListSorterSelector,
    BuyNumber,
    FlaggedName,
    Avatar,
  },
  interval: undefined,
  props: {
    buy: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    buysForSorting: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    now: new Date(),
  }),
  computed: {
    due() {
      return parseISO(this.buy.estimatedPickupAt);
    },
    showSortingBtn() {
      return this.buysForSorting.some((buy) => buy?.id === this.buy?.id);
    },
    hasSorter() {
      return !!this.buy.sorterId;
    },
    // Is due less than 5 minutes from now
    isDueInFive() {
      return isAfter(addMinutes(this.now, 5), this.due);
    },
    // Is due less than 15 minutes from now
    isDueSoon() {
      return isAfter(addMinutes(this.now, 15), this.due);
    },
    isWarning() {
      return !this.isError && this.isDueSoon;
    },
    isError() {
      return (
        isAfter(this.now, this.due) ||
        this.isDueInFive ||
        (!this.hasSorter && this.isDueSoon)
      );
    },
    dynamicClasses() {
      return {
        error: this.isError,
        warning: this.isWarning,
        "lighten-4": this.isWarning || this.isError,
      };
    },
  },
  created() {
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval();
  },
  methods: {
    // Sets "now" every 30s. Used for time-based row background colors
    startInterval() {
      this.$options.interval = setInterval(
        () => (this.now = new Date()),
        30000
      );
    },
    stopInterval() {
      clearInterval(this.$options.interval);
    },
    onShowItem() {
      this.$emit("show", this.buy.id);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    :class="{
      ...dynamicClasses,
      'rai-data': true,
      'py-2': true,
    }"
    v-on="$listeners"
    @showItem="onShowItem"
  >
    <template #desktop>
      <td class="pl-8">
        <BuyNumber :trans-id="buy.transId" class="text-truncate" />
      </td>
      <td>
        <FlaggedName
          :customer="buy.customer"
          :darken="isError"
          class="buy-list-item-name font-weight-bold"
        />
        <span
          class="font-weight-bold pl-2 text--secondary"
          v-text="buy.containerNum"
        />
        -
        <span class="text--secondary" v-text="buy.containerDescription" />
        <span class="font-italic text--secondary" v-text="buy.containerSize" />
      </td>
      <td class="text--secondary sorter-cell">
        <ListSorterSelector
          v-if="showSortingBtn || hasSorter"
          :buy-id="buy.id"
          :has-sorter="hasSorter"
          label="Start"
        />
      </td>
    </template>

    <template #mobile>
      <v-list-item-content
        class="item__content buy-number-cell-mobile py-0 align-self-start"
      >
        <BuyNumber :trans-id="buy.transId" hide-prefix class="text-truncate" />
      </v-list-item-content>
      <v-list-item-content class="item__content py-0 pl-2 align-self-start">
        <FlaggedName :customer="buy.customer" class="d-inline" />
        <v-list-item-subtitle>
          <span class="font-weight-bold" v-text="buy.containerNum" /> -
          <span v-text="buy.containerDescription" />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="my-0 justify-start align-center">
        <v-list-item-action-text class="item__content"
          ><ListSorterSelector
            v-if="showSortingBtn || hasSorter"
            :buy-id="buy.id"
            :has-sorter="hasSorter"
            hide-avatar
            label="Start"
        /></v-list-item-action-text>
        <v-list-item-avatar v-if="buy && buy.sorterId" class="ma-0" height="24">
          <Avatar :employee-id="buy.sorterId" size="24" />
        </v-list-item-avatar>
      </v-list-item-action>
    </template>
  </VxDataListItem>
</template>

<style scoped>
.buy-list-item-name {
  display: inline !important;
}

.sorter-cell {
  height: 36px !important;
}

.buy-number-cell-mobile {
  max-width: 40px;
}
</style>
